import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { fr_FR, provideNzI18n } from 'ng-zorro-antd/i18n';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { routes } from './app.routes';
import { authorizationInterceptor } from './core/interceptors/authorization.interceptor';
import { errorInterceptor } from './core/interceptors/error.interceptor';
import { NzConfig, provideNzConfig } from 'ng-zorro-antd/core/config';
import { NgxEditorConfig, NgxEditorModule } from 'ngx-editor';

registerLocaleData(localeFr, 'fr');

const custom_fr_FR = {
  ...fr_FR,
  DatePicker: {
    ...fr_FR.DatePicker,
    lang: {
      ...fr_FR.DatePicker.lang,
      rangeQuarterPlaceholder: ['Trimestre de début', 'Trimestre de fin'],
    },
  },
  QRCode: {
    ...fr_FR.QRCode,
    scanned: 'Scanné',
  },
};

const translateConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: createTranslateLoader,
    deps: [HttpClient],
  },
  compiler: {
    provide: TranslateCompiler,
    useClass: TranslateMessageFormatCompiler,
  },
};

const ngZorroConfig: NzConfig = {
  icon: { nzTheme: 'outline' },
  modal: { nzMaskClosable: false },
};

const ngxEditorConfig: NgxEditorConfig = {
  locals: {
    // menu
    bold: 'Gras',
    italic: 'Italique',
    code: 'Code',
    blockquote: 'Blockquote',
    underline: 'Souligner',
    strike: 'Barrer',
    bullet_list: 'Liste à puces',
    ordered_list: 'Liste ordonnée',
    heading: 'Titre',
    h1: 'Header 1',
    h2: 'Header 2',
    h3: 'Header 3',
    h4: 'Header 4',
    h5: 'Header 5',
    h6: 'Header 6',
    align_left: 'Alignement à gauche',
    align_center: 'Alignement au centre',
    align_right: 'Alignement à droite',
    align_justify: 'Justifier',
    text_color: 'Couleur du texte',
    background_color: 'Couleur du fond',
    redo: 'Refaire',
    undo: 'Annuler',

    // popups, forms, others...
    url: 'URL',
    text: 'Texte',
    openInNewTab: 'Ouvrir dans un nouvel onglet',
    insert: 'Insérer',
    altText: 'Texte Alternatif',
    title: 'Titre',
    remove: 'Supprimer',
    enterValidUrl: 'Entrer une URL valide',
  },
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      TranslateModule.forRoot(translateConfig),
      NgxEditorModule.forRoot(ngxEditorConfig)
    ),
    provideHttpClient(
      withInterceptors([authorizationInterceptor, errorInterceptor])
    ),
    provideAnimations(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideNzConfig(ngZorroConfig),
    provideNzI18n(custom_fr_FR),
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'medium' } },
    provideRouter(
      routes,
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'top',
      })
    ),
  ],
};
