import { computed, Injectable, signal } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { UserPermission } from '../../constants/user-permission.enum';
import { TOKEN_ID } from '../../constants/user-token.constant';
import { Token } from '../../models/api/token';

@Injectable({
  providedIn: 'root',
})
export class TokenStoreService {
  private readonly $_token = signal<Token | undefined>(undefined);

  readonly $isAuthenticated = computed(() => !!this.$_token());
  readonly $accessToken = computed(() => this.$_token()?.accessToken);

  readonly $userFromToken = computed(() => {
    return this.$accessToken()
      ? (jwt_decode(this.$accessToken()!) as Record<string, unknown>)
      : null;
  });
  readonly $loggedUserId = computed(
    () => this.$userFromToken()?.['sub'] as number
  );
  readonly $userPermissions = computed(() =>
    this.$userFromToken()
      ? (this.$userFromToken()?.['permissions'] as UserPermission[])
      : []
  );

  readonly $userProfile = computed(
    () => this.$userFromToken()?.['profile'] as string
  );
  readonly $isAdminProfile = computed(() => 'ADMIN' === this.$userProfile());

  readonly $isDeliverProfile = computed(
    () => 'DELIVER' === this.$userProfile()
  );

  constructor() {
    this.initTokenFromStorage();
  }

  private _getTokenFromStorage() {
    const token = localStorage.getItem(TOKEN_ID);
    return token ? JSON.parse(token) : undefined;
  }

  public initTokenFromStorage() {
    this.$_token.set(this._getTokenFromStorage());
  }

  public setToken(token: Token) {
    localStorage.setItem(TOKEN_ID, JSON.stringify(token));
    this.$_token.set(token);
  }

  public clear() {
    localStorage.removeItem(TOKEN_ID);
    this.$_token.set(undefined);
  }
}
